<script>
	let pageName = "About";

	document.title = pageName;
</script>

<main>
	<h1>Coming soon...</h1>
	<p>
		<span> 1. Enter the amount of NIM per cashlink </span><br />
		<span> 2. Enter the amount of cashlinks you want to create </span><br />
		<span>
			3. (Optional) Include a message that will will be stored in the Cashlink
		</span><br />
		<span>
			4. Choose a Network Fee, this fee will be paid on every cashlink funding
			process.
			<br />If you create more than 10 Cashlinks the minimum fee is
			<b>Standard</b>.
		</span><br />
		<span>
			5. All done, click on Create Cashlinks and proceed with the payout.
		</span><br />
	</p>
	<a
		href="https://github.com/Albermonte/Nimiq-Multi-Cashlink#how-does-it-work"
		target="_blank">Want to know more?</a
	>
</main>

<style>
	main {
		display: grid;
		place-items: center;
		height: 100%;
		padding-bottom: 5rem;
	}
	p {
		text-align: center;
	}
</style>
